jQuery(function($){
var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 767;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得

  /*------------------------*/
  //ページトップ
  /*------------------------*/
	var topBtn = $('.pageTop');
  topBtn.hide();
  var docH = $(document).innerHeight(),
  winH = $(window).height(),
  footeH = $('.footer').outerHeight(),
  topBtnPos = docH - winH - footeH,
  flag = false;
	// スクロールが200に達したらボタン表示
	$(window).scroll(function () {
    if (!flag) { //フラグがtrueでなければ取得
      docH = $(document).height(),
      winH = $(window).height()
      footeH = $('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH;
      flag = true;
    }
		if ($(this).scrollTop() > 200 && !topBtn.hasClass("float") && !topBtn.hasClass("nonfloat")) {
      topBtn.fadeIn().addClass("float");
    } else if ($(this).scrollTop() < topBtnPos && topBtn.hasClass("nonfloat")) {
      topBtn.removeClass("nonfloat").addClass("float");
    } else if ($(this).scrollTop() > topBtnPos && topBtn.hasClass("float")) {
      topBtn.removeClass("float").addClass("nonfloat");
		} else if ($(this).scrollTop() < 200) {
      topBtn.fadeOut().removeClass("float nonfloat");
    }
	});
	// スクロールしてトップ
	$(".pageTop").on('click',function () {
		$('body,html').animate({
				scrollTop: 0
		}, 500);
		return false;
  });

  //スムーススクロール（ページ内リンク）
  $('a[href^="#"]').on("click", function () {
    var f = 600;
    e = $(this).attr("href"),
    g = $(e == "#" || e == "" ? "html" : e),
    h = $(".l-header").height(),
    d = g.offset().top - h;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });
  $('.js-scroll').on("click", function () {
    var f = 600;
    e = $(this).attr("href"),
    g = $(e == "#" || e == "" ? "html" : e),
    h = $(".l-header").height(),
    d = g.offset().top - h;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //tab
  $('.tabMenu .tabMenu__item').on('click',function() {
    var i = $(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    $(this).siblings().removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    $(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    $(this).parent().next(".tabItem").children(".tabList").hide().removeClass("show").eq(i).fadeIn(300).addClass("show");
  });

  //accordion
  $(".js-accordion__btn").on('click',function() {
    $(this).toggleClass("open");
    $(this).next(".js-accordion__item").slideToggle();
    $('.js-accordion__btn').not($(this)).removeClass("open");
    $('.js-accordion__btn').not($(this)).next('.js-accordion__item').slideUp(300);
  });

  //pulldown
  $(".js-pulldown .js-pullDownList").on('click',function() {
    $(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });

  //SPグロナビ内トグルメニュー
  //.showがついてない物に.hideを付与
  // $(".gNav--sp .js--gNavToggleItem").css("display", "none");
  $(".gNav--sp .js--gNavToggleItem").not((".show")).addClass("hide");
  $('.js--gNavToggle').on('click', function () {
    $(this).toggleClass("active");
    $(this).next().slideToggle();
    $('.js--gNavToggle').not($(this)).removeClass("active");
    $('.js--gNavToggle').not($(this)).next('.js--gNavToggleItem').slideUp(300);
  })

  //sceneスライダー
  function jsFeature() {
    $(".js--scene-hover").length && $(".js--scene-hover").hover(function () {
      $(this).parents(".js--scene").find(".js--scene-hover.is-active").removeClass("is-active"), $(this).addClass("is-active");
      var e = $(this).data("photo");
      $(this).parents(".js--scene").find(".js--scene-photo .photoBox__item").removeClass("is-active"), $(e).addClass("is-active")
    });
  }
  $(window).on("load", function () {
    jsFeature();
  });
  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------*/
      /* PCの時
      /*-------------------------------------*/
    }
  })();

  /*------------------------*/
  //バーガーメニュー表示・非表示
  /*------------------------*/
  var burgerBtn = $('.burger');
  var headerHeight = $('.header').innerHeight();
  var headerInnerHeight = $('.gNavList').height();
  // スクロールがヘッダーの高さに達したらボタン表示
  $(window).scroll(function () {
    if (default_width >= 920 && $(this).scrollTop() > headerInnerHeight) {
      $(".gNavList").addClass("fixedNav");
    } else if (default_width >= 920 && $(this).scrollTop() < headerInnerHeight) {
      if ($(".gNavList").hasClass("fixedNav")) {
        $(".gNavList").removeClass("fixedNav");
      }
    } else {
      if ($(".gNavList").hasClass("fixedNav")) {
        $(".gNavList").removeClass("fixedNav");
      }
    }
  });
	//バーガーボタンクリック
	burgerBtn.on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= 1040) {
      burgerBtn.toggleClass("is-open");
      if ($(".gNav--sp").hasClass("show")) {
        $(".gNav--sp").removeClass("show").addClass("hide");
      } else if ($(".gNav--sp").hasClass("hide")) {
        $(".gNav--sp").removeClass("hide").addClass("show");
      } else {
        $(".gNav--sp").addClass("show");
      }
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").height(window.innerHeight).removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= 1040) {
  		$(".gNav--sp").removeClass("show").addClass("hide");
  		burgerBtn.removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
	});

  //PCメガメニュー処理
  $('.pullDownHover').mouseover(function(e) {
    // マウスポインターが乗った時の動作
    $(this).addClass("hover");
    console.log("a");
    $(".pullDownHover a").on('click',function(){
      e.stopPropagation();
      $(".pullDownHover").removeClass("hover");
      console.log("b");
    });
    return;
  })
  .mouseout(function(e) {
    // マウスポインターが外れた時の動作
    $(this).removeClass("hover");
    console.log("c");
  });
  //ページ内リンクをクリック時にメガメニューを閉じる
  // .children("a").on('click',function(e){
  //   if ($(this).parents(".pullDownHover").hasClass("hover")) {
  //     $(".pullDownHover").removeClass("hover");
  //     console.log("a");
  //     e.preventDefault();
  //   }
  // });

  /*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".gNav--sp").removeClass("show hide");
				burgerBtn.removeClass("is-open");
				$(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".gNav--sp").removeClass("show hide");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
        // $header.removeClass(scrollClass);
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
			}

      docH = $(document).height(),
      winH = $(window).height()
      footeH = $('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH;
		}, 200);
	});
});

  /*slider serviceMenu*/
  $(function() {
    //slider設置
    const $slider = $('.slider--serviceMenu');
    if ($slider.length) {
      $slider.slick({
        centerMode: true,
        centerPadding: '10%',
        slidesToShow: 3,
        dots: true,
        responsive: [
          {
            breakpoint: 1040,
            settings: {
              centerPadding: '210px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: false,
              slidesToShow: 1
            }
          }
        ]
      });
    }
  });

  /*slider thumb*/
  if ($('.c-slider--thumb').length) {
    $('.c-slider--thumb').slick({
      dots:true,
      arrows: false,
      prevArrow: '<a class="slick-prev" href="#"><i data-icon="ei-arrow-left" data-size="m"></i></a>',
      nextArrow: '<a class="slick-next" href="#"><i data-icon="ei-arrow-right" data-size="m"></i></a>',
      customPaging: function(slick,index) {
        var targetImage = slick.$slides.eq(index).find('img').attr('src');
        return '<img src=" ' + targetImage + ' "/>';
      }
    });
  }
});